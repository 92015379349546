import * as Sentry from '@sentry/react';

import { APP_NAME, BUILD_NAME, env, sentryOptions, isBuilt } from '@/constants';

export const isPreloadError = (error: Error | unknown) => {
  return (
    error instanceof Error &&
    [
      /Failed to fetch dynamically imported module/,
      /Unable to preload CSS for/,
      /Importing a module script failed/,
    ].some((regex) => regex.test(error.message))
  );
};

const initSentry = () => {
  const initialOptions: Sentry.BrowserOptions = {
    dsn: sentryOptions.dsn,
    environment: env,
    integrations() {
      return [];
    },
    release: `${BUILD_NAME} (${APP_NAME})`,
    beforeSend(event, hint) {
      if (isPreloadError(hint.originalException)) {
        return null;
      }
      return event;
    },
  };

  if (isBuilt) {
    Sentry.init(initialOptions);
  }
};

export const loadSentryIntegrations = () => {
  const isProductionEnv = env === 'production';

  if (isBuilt) {
    console.log('Loading Sentry Integrations');

    const existingOptions = Sentry.getClient()?.getOptions() || {};
    const defaultIntegrations = Sentry.getDefaultIntegrations({}) || [];

    Sentry.init({
      ...existingOptions,

      integrations: [
        ...defaultIntegrations,
        Sentry.replayIntegration({
          networkDetailAllowUrls: [/\/(shopify|api)/],
        }),
      ],

      // Session Replay
      replaysSessionSampleRate: isProductionEnv ? 0.05 : 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: isProductionEnv ? 0.8 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

export default initSentry;

import React, { Suspense, useEffect } from 'react';
import Mixpanel from 'mixpanel-browser';
import { mixPanelConfig } from './constants';

import { APP_NAME, BUILD_NAME, isBuilt } from './constants';
import { loadSentryIntegrations } from './util/sentry';

Mixpanel.init(mixPanelConfig.key);
Mixpanel.set_config({ persistence: 'localStorage', ignore_dnt: true });

const ShopifyAppContainer = React.lazy(() => import('./components/Apps/ShopifyAppContainer'));
const StandaloneAppContainer = React.lazy(() => import('./components/Apps/StandaloneAppContainer'));
declare global {
  interface Window {
    paypal: any;
  }
}

if (isBuilt) {
  console.log(`Build: ${BUILD_NAME}`);
}

const UnknownApp = () => {
  return <div>Unknown Application</div>;
};

const Root = () => {
  const AppContainer =
    {
      shopify: ShopifyAppContainer,
      standalone: StandaloneAppContainer,
    }[APP_NAME] || UnknownApp;

  useEffect(() => {
    loadSentryIntegrations();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AppContainer />
    </Suspense>
  );
};

export default Root;

export * from './categories';
export * from './platen';
export * from './api';
export * from './templates';
export * from './settings';
export * from './artwork';
export * from './usStatesByCode';
export * from './shopify';
export * from './standalone';
export * from './thirdPartyServices';
export * from './misc';

export enum PLATFORM_ENUM {
  FUEL = 'fuel',
  SHOPIFY = 'shopify',
}

export enum SHIPPING_METHOD_ENUM {
  EXPEDITED = 'expedited',
  STANDARD = 'standard',
}

export enum PLAN_NAME_ENUM {
  TRIAL = 'trial',
  BASIC = 'basic',
  AFFILIATE = 'affiliate',
}

export enum FEATURES {
  NECK_LABEL = 'Branded Neck Label',
  CUSTOM_RETURN = 'Custom Return Address',
  COLOR_CHANGING = 'Color Changing',
}

export enum TIERS {
  PROMOTION = 'PromotionTierV0',
  BASE = 'BaseTier',
  BETA = 'BetaTier',
  POWER = 'PowerSellerTier',
  DEV = 'DevTier',
  ADVANCE = 'AdvancedSeller',
}

export enum FILTER_BY {
  ALL = 'ALL',
  QUICKSHIP = 'Quickship',
  NEW = 'New',
  POPULAR = 'Popular',
}

export enum SHOW_SHIPPING_COST {
  DOMESTIC = 'US',
  INTERNATIONAL = 'Worldwide',
  NON = "Don't show Shipping Cost",
}

export enum ORDER_COLLECTION {
  'Headwear & Apparel' = 1,
  'Accessories & Drinkware' = 2,
  'Home & Living' = 3,
}

export enum ORDER_CATEGORY {
  'Embroidered Hats' = 1,
  'Apparel' = 2,
  'Phone Cases' = 3,
  'Mugs' = 4,
  'Bottles & Tumblers' = 5,
  'Seasonal Items' = 6,
  'Beddings' = 7,
  'Home Decor' = 8,
  'Wall Art' = 9,
  'Fitness & Wellness' = 10,
}

export const collectionTreeOrder: string[] = [
  'Headwear & Apparel|Embroidered Hats|Trucker hats',
  'Headwear & Apparel|Embroidered Hats|Dad caps',
  'Headwear & Apparel|Embroidered Hats|5 panel',
  'Headwear & Apparel|Embroidered Hats|6 panel',
  'Headwear & Apparel|Embroidered Hats|Beanies',
  'Headwear & Apparel|Apparel|Classic T-Shirts',
  'Headwear & Apparel|Apparel|Unisex Tanks',
  'Headwear & Apparel|Apparel|Premium Fit Ladies Tees',
  'Headwear & Apparel|Apparel|Ladies Tanks Tops',
  'Headwear & Apparel|Apparel|Long Sleeve Tees',
  'Headwear & Apparel|Apparel|Youth T-Shirts',
  'Headwear & Apparel|Apparel|Hoodies',
  'Headwear & Apparel|Apparel|Premium Fit Mens Tees',
  'Headwear & Apparel|Apparel|V-Necks',
  'Headwear & Apparel|Apparel|Sweatshirts',
  'Headwear & Apparel|Apparel|Polo Shirts',
  'Headwear & Apparel|Apparel|Scarves',
  'Accessories & Drinkware|Phone Cases|iPhone Cases',
  'Accessories & Drinkware|Phone Cases|Samsung Cases',
  'Accessories & Drinkware|Mugs|Mugs',
  'Accessories & Drinkware|Bottles & Tumblers|Tumblers',
  'Accessories & Drinkware|Bags|Tote Bags',
  'Home & Living|Seasonal Items|Ornaments|Acrylic',
  'Home & Living|Seasonal Items|Ornaments|Ceramic / Porcelain',
  'Home & Living|Seasonal Items|Ornaments|Wood',
  'Home & Living|Beddings|Blankets',
  'Home & Living|Home Decor|Yard Sign',
  'Home & Living|Home Decor|Flags',
  'Home & Living|Home Decor|Doormat',
  'Home & Living|Wall Art|Poster|Matte',
  'Home & Living|Wall Art|Poster|Gloss',
  'Home & Living|Wall Art|Canvas|Wrap Canvas',
  'Home & Living|Wall Art|Canvas|Hanging Canvas',
  'Home & Living|Wall Art|Canvas|Floating Canvas',
  'Home & Living|Wall Art|Fitness & Wellness|Yoga Mats',
];

export const tiersName: Record<TIERS, string> = {
  [TIERS.PROMOTION]: 'Promotion Tier',
  [TIERS.BASE]: 'Base Tier',
  [TIERS.BETA]: 'Beta Tier',
  [TIERS.POWER]: 'Power Tier',
  [TIERS.DEV]: 'Dev Tier',
  [TIERS.ADVANCE]: 'Advance Tier',
};

// This is the quantity of colors the palette will show in ProductItem -> catalog product card
export const NUMBER_COLORS_SHOW_COLPASE = 10;
export const COLOR_PALETTE_MAX = 12;

export const BUILD_NAME = import.meta.env.VITE_BUILD_NAME ?? 'N/A';

export const CAN_EXPEDITE_SHIPPING_COUNTRIES = ['US'];

export enum contactInfoManagment {
  MANAGE_CONTACT_INFO = 'Manage Contact Info',
  UNLOCK_CONTACT_INFO = 'Unlock Intro Price',
}

export const DEFAULT_MARGIN = '66.67';

import { Auth0ProviderOptions } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

import { AppName, env, NodeEnv, APP_NAME } from './misc';

const auth0ConfigByEnv: Record<NodeEnv, Auth0ProviderOptions> = {
  test: {
    domain: 'auth-stage.getfuelpod.com',
    clientId: 'Yunp12SAuLzM3T2DizXR3KaFMZ38071N',
    authorizationParams: {
      audience: 'fuel-rest.ooapi.com',
    },
  },
  development: {
    domain: 'auth-stage.getfuelpod.com',
    clientId: 'Yunp12SAuLzM3T2DizXR3KaFMZ38071N',
    authorizationParams: {
      audience: 'fuel-rest.ooapi.com',
    },
  },
  stage: {
    domain: 'auth-stage.getfuelpod.com',
    clientId: 'Yunp12SAuLzM3T2DizXR3KaFMZ38071N',
    authorizationParams: {
      audience: 'fuel-rest.ooapi.com',
    },
  },
  production: {
    domain: 'auth.getfuelpod.com',
    clientId: 'NZXo5iWD8wSyZbFuDQgC0EENJMBLg4Gd',
    authorizationParams: {
      audience: 'fuel-app',
    },
  },
};

const sentryOptionsByAppName: Record<AppName, Partial<Sentry.BrowserOptions>> = {
  shopify: {
    dsn: 'https://0d4d488a6b5dc962d99e30581a9b4484@o4505507855925248.ingest.sentry.io/4506063124430848',
  },
  standalone: {
    dsn: 'https://e65b24b9161eecf35f16003765a55d0b@o4505507855925248.ingest.sentry.io/4506064127000576',
  },
};

const mixpanelConfigByEnv: Record<NodeEnv, { key: string }> = {
  test: {
    key: '6f3600ae6070ded89d6a4d4532702caf',
  },
  development: {
    key: '6f3600ae6070ded89d6a4d4532702caf',
  },
  stage: {
    key: '6f3600ae6070ded89d6a4d4532702caf',
  },
  production: {
    key: '460731f3fecc2dfce6287265f3baa4ea',
  },
};

const mapboxConfigByEnv: Record<NodeEnv, { publicAccessToken: string }> = {
  test: {
    publicAccessToken:
      'pk.eyJ1IjoiYmFvLWhvLXNjYWxhYmxlIiwiYSI6ImNscnE4NWk5cjAwZHEybHA5aDM2cjk3NTQifQ.XJyR6BRaqciLq-DZCYVZ4w',
  },
  development: {
    publicAccessToken:
      'pk.eyJ1IjoiYmFvLWhvLXNjYWxhYmxlIiwiYSI6ImNscnE4NWk5cjAwZHEybHA5aDM2cjk3NTQifQ.XJyR6BRaqciLq-DZCYVZ4w',
  },
  stage: {
    publicAccessToken:
      'pk.eyJ1IjoiYmFvLWhvLXNjYWxhYmxlIiwiYSI6ImNscnE4NWk5cjAwZHEybHA5aDM2cjk3NTQifQ.XJyR6BRaqciLq-DZCYVZ4w',
  },
  production: {
    publicAccessToken:
      'pk.eyJ1IjoiYmFvLWhvLXNjYWxhYmxlIiwiYSI6ImNscnE4NWk5cjAwZHEybHA5aDM2cjk3NTQifQ.XJyR6BRaqciLq-DZCYVZ4w',
  },
};

export const sentryOptions = sentryOptionsByAppName[APP_NAME];

export const auth0Config = auth0ConfigByEnv[env];

export const mixPanelConfig = mixpanelConfigByEnv[env];

export const mapboxConfig = mapboxConfigByEnv[env];

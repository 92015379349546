import { Size } from '@/interfaces';

// Copy of server/constants/enums, which can't be imported on client
const SIZE_MAP: { [key: string]: string } = {
  XS: 'xsml',
  S: 'sml',
  M: 'med',
  L: 'lrg',
  XL: 'xlg',
  '2XL': 'xxl',
  '3XL': 'xxxl',
  '4XL': 'xxxxl',
  '5XL': 'xxxxxl',
  '6XL': 'xxxxxxl',
  '11OZ': '11oz',
  '15OZ': '15oz',
  '11X17': 'one',
  '17X11': 'one',
  '16X24': 'one',
  '24X16': 'one',
  '24X36': 'one',
  '36X24': 'one',
  '11X14': 'one',
  '14X11': 'one',
  '12X18': 'one',
  '18X12': 'one',
  '18X24': 'one',
  '24X18': 'one',
  '20X24': 'one',
  '24X20': 'one',
  '16X20': 'one',
  '20X16': 'one',
  '20X30': 'one',
  '30X20': 'one',
  '36X48': 'one',
  '48X36': 'one',
  '8X12': 'one',
  '12X8': 'one',
  '12X16': 'one',
  '16X12': 'one',
  '27X40': 'one',
  '40X27': 'one',
  ONE: 'one',
  '-': 'one',
};

export const sizeMapper: { [key: string]: string } = {
  xsml: Size.XS,
  sml: Size.S,
  med: Size.M,
  lrg: Size.L,
  xlg: Size.XL,
  xxl: Size.XXL,
  xxxl: Size.XXXL,
  xxxxl: Size.XXXXL,
  xxxxxl: Size.XXXXXL,
  xxxxxxl: Size.XXXXXXL,
  '11oz': Size.Oz11,
  '15oz': Size.Oz15,
  '11x17': Size.s11x17,
  '17x11': Size.s17x11,
  '16x24': Size.s16x24,
  '24x16': Size.s24x16,
  '24x36': Size.s24x36,
  '36x24': Size.s36x24,
  '11x14': Size.s11x14,
  '14x11': Size.s14x11,
  '12x18': Size.s12x18,
  '18x12': Size.s18x12,
  '18x24': Size.s18x24,
  '24x18': Size.s24x18,
  '20x24': Size.s20x24,
  '24x20': Size.s24x20,
  '16x20': Size.s16x20,
  '20x16': Size.s20x16,
  '20x30': Size.s20x30,
  '30x20': Size.s30x20,
  '36x48': Size.s36x48,
  '48x36': Size.s48x36,
  '8x12': Size.s8x12,
  '12x8': Size.s12x8,
  '12x16': Size.s12x16,
  '16x12': Size.s16x12,
  '27x40': Size.s27x40,
  '40x27': Size.s40x27,
  one: Size.Dash,
  '-': 'one',
};

export const mapSizes = (sizeChart: string[]) => {
  return sizeChart.map((size) => mapSizeSpToFuel(size));
};

export const mapSizeSpToFuel = (size: string) => {
  return sizeMapper[size.toLowerCase()];
};

export const mapSizeFuelToSp = (size: string): string => {
  return SIZE_MAP[size.toUpperCase()];
};

export default mapSizes;

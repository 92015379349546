// uncategorized

export const env: NodeEnv = import.meta.env.VITE_BUILD_ENV || 'development';

export const APP_NAME: AppName = import.meta.env.VITE_APP_NAME ?? 'standalone';

export const isBuilt = import.meta.env.MODE === 'production';

export type NodeEnv = 'production' | 'stage' | 'test' | 'development';

export type AppName = 'shopify' | 'standalone';

export enum LOCAL_STORAGE_ITEMS {
  FUEL_TOKEN = 'fuel-token',
  REDIRECT_URL = 'redirect-url',
  RELOADED_ON_401 = 'reloaded-on-401',
}

export const saveUrlToLocalStorage = () => {
  try {
    // root is the default so we could ignore it
    window.location.pathname !== '/' &&
      localStorage.setItem(
        LOCAL_STORAGE_ITEMS.REDIRECT_URL,
        window.location.pathname + window.location.search
      );
  } catch (e) {
    console.warn('localStorage blocked by client, skipped redirect-url saving');
  }
};

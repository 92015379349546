import Axios from 'axios';

import { LOCAL_STORAGE_ITEMS, saveUrlToLocalStorage } from '@/constants';

export const axios = Axios.create();

const reloadOn401 = () => {
  try {
    const reloaded = window.localStorage.getItem(LOCAL_STORAGE_ITEMS.RELOADED_ON_401) === 'true';

    if (!reloaded) {
      window.localStorage.setItem(LOCAL_STORAGE_ITEMS.RELOADED_ON_401, 'true');

      // reload to force reauthentication
      window.location.reload();
    }
  } catch (e) {
    console.error('localStorage is not available');
  }
};

const unsetReloaded = () => {
  try {
    window.localStorage.removeItem(LOCAL_STORAGE_ITEMS.RELOADED_ON_401);
  } catch (e) {
    console.error('localStorage is not available');
  }
};

axios.interceptors.response.use(
  (response) => {
    unsetReloaded();
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      console.error('Got a forbidden response, NOT redirecting back to install...');
      saveUrlToLocalStorage();
      reloadOn401();
    }
    return Promise.reject(error);
  }
);

export default axios;
